<template>
  <div>
    <v-img 
      :src="header.mediaUrl"
      class="header-image">
    </v-img>

    <v-layout column v-if="header.type == 'DOCUMENT'">
      <div class="pdf-container" @click="openPdf(header.mediaUrl)">
        <pdf class="pdf-preview" :src="header.mediaUrl"> </pdf>
      </div>
      <div
        :class="`${
          this.$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'
        }`"
        class="header-document pa-2 d-flex align-center mb-2"
      >
        <img
          class="document-icon"
          :src="'/img/icons/pdf_icon.png'"
          alt="pdf-icon"
        />
        <div class="document-text ml-3">
          <span>{{ header.filename }}</span>
          <h5>50 kB <span>⋅</span> PDF</h5>
        </div>
        <v-spacer></v-spacer>
        <v-btn
          icon
          outlined
          class="mr-4"
          @click="downloadWithVueResource(header.mediaUrl)"
        >
          <v-icon>file_download</v-icon>
        </v-btn>
      </div>
      <div
        v-if="header.type == 'TEXT'"
        class="header-document"
      ></div>
    </v-layout>
  </div>
</template>

<script>
import pdf from "vue-pdf";
import axios from "axios";
export default {
  components: {
    pdf,
  },

  methods: {
    forceFileDownload(response) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", this.header.filename); //or any other extension
      document.body.appendChild(link);
      link.click();
    },

    openPdf(link) {
      window.open(link, "_blank");
    },

    downloadWithVueResource(url) {
      axios({
        method: "get",
        url: url,
        responseType: "arraybuffer",
      })
        .then((response) => {
          this.forceFileDownload(response);
        })
        .catch(() => console.log("error occured"));
    },
  },
  props: {
    header: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.pdf-container{
  cursor: pointer;
}
.pdf-preview {
  height: 100px;
  overflow: hidden;
  .canvas {
    margin-top: -10px !important;
  }
}
.header-image {
  margin-bottom: 10px; 
  border-radius: 5px;
  background-position: center;
}
.header-document {
  height: 60px;
  background-size: 200%;
  background-position: center;
  border-radius: 0px 0px 5px 5px;
  .document-icon {
    max-height: 100%;
  }
  .document-text {
    span {
      font-size: 20px;
    }
    h5 {
      font-size: 14px;
      font-weight: 300;
      span {
        font-weight: 800;
      }
    }
  }
}
</style>