<template>
  <div>
    <!-- BOTÔES CASO O TIPO SELECIONADO SEJA CHAMAR PARA AÇÃO -->
    <div
      v-if="selectedInitiative.buttons_type == 'QUICK_REPLY'"
      class="d-flex flex-column"
    >
      <div
        class="d-flex flex-grow-1"
        v-if="filteredButtons"
        :class="
          Object.keys(filteredButtons).length <= 2 ? 'flex-column' : 'felx-row'
          "
      >
        <!-- BOTÂO 1 -->
        <v-btn class="flex-grow-1 blue--text"
        v-if="Object.keys(filteredButtons).length > 0"
        :class="
          Object.keys(filteredButtons).length > 2 ? 'mr-1' : 'mb-1' 
        "
          >{{ filteredButtons[0].label }}
        </v-btn>
        <!-- BOTÃO 2 -->
        <v-btn
          v-if="Object.keys(filteredButtons).length > 1"
          class="flex-grow-1 blue--text"
          :class="
            Object.keys(filteredButtons).length > 2 ? ' ml-1' : ''
          "
          >{{ filteredButtons[1].label }}
        </v-btn>
      </div>
      <!-- BOTÃO 3 -->
      <div v-if="Object.keys(filteredButtons).length > 2">
        <v-btn style="width: 100%; margin-top: 5px" class="blue--text"
          >{{ filteredButtons[2].label }}
        </v-btn>
      </div>
    </div>
    <!-- BOTÔES CASO O TIPO SELECIONADO SEJA CHAMAR PARA AÇÃO -->
    <div v-if="selectedInitiative.buttons_type == 'CALL_TO_ACTION'">
      <div
        class="d-flex flex-column call-section"
        v-for="(item, i) in selectedInitiative.buttons"
        v-bind:key="i"
      >
        <v-btn
          text
          class="flex-grow-1 call-button"
          color="blue"
          @click="copyText(`https://quiteja.com.br/${item.parameter}`)"
          ><v-icon left>{{item.type == 'PHONE' ? 'phone': 'open_in_new'}}</v-icon>
          {{ item.label }}
        </v-btn>
        <v-divider></v-divider>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filteredButtons: {},
    };
  },
  mounted() {
      if (this.selectedInitiative
          && this.selectedInitiative.buttons_type != null ) {
        let asArray = Object.entries(this.selectedInitiative.buttons);
        let filtered = asArray.filter((x) => x[1].label != "");
        this.filteredButtons = Object.fromEntries(filtered);
        console.log(this.filteredButtons)
      }
    },
  methods: {
  },
  props: {
    selectedInitiative: {
      type: Object,
    },
    // filteredButtons: {
    //   type: Object,
    // },
  },
  methods: {
    copyText(text) {
      navigator.clipboard.writeText(text)
      this.$store.commit("showSnackbar", "Texto copiado!")
    }
  }
};
</script>

<style lang="scss" scoped>
.v-btn {
  border-radius: 10px;
  text-transform: unset !important;
}
.call-button {
  border-radius: 0px;
  background-color: transparent;
}
.call-section {
  background-color: whitesmoke;
  margin-top: -7px;
  border-radius: 0px 0px 10px 10px;
}

.light-box-message {
  background-color: #d9fdd3 !important;
  background: #d9fdd3 !important;
}d9fdd3

.dark-box-message {
  background-color: #005c4b !important;
  background: #005c4b !important;
}
// .btn {
    
// }
</style>