<template>
  <v-layout class="row-message" ma-2 justify-end grow>
    <v-layout class="tes d-flex flex-column">
      <v-card
        class="message"
      >
        <v-card-text 
          class="wppBack"
          :class="`${this.$vuetify.theme.dark ? 'dark-box-message' : 'light-box-message'}`"
        >
          <v-layout class="box-message" column>
            <Header
              v-if="event.content.header"
              :header="event.content.header"
            >
            </Header>

            <div class="text--primary">
              <span
                class="footer-text2"
                v-html="event.content.html"
              ></span>
            </div>

            <v-layout flex>
              <p>{{ event.content.footer_text }}</p>
              <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-layout mt-2 v-on="on">
                  <v-icon outlined small :key="event.sent_at" v-if="!event.sent_at && !event.error_at">av_timer</v-icon>
                  <v-icon :color="doneAllIconColor(event.seen_at)" outlined small :key="event.seen_at" v-else-if="event.seen_at || event.sent_at">done_all</v-icon>
                  <v-icon outlined small color="red" :key="event.error_at" v-else>error</v-icon>
                </v-layout>
              </template>
              <span>{{
                event.status_from_delivery
              }}</span>
            </v-tooltip>
              <div class="time-container">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <span class="date-event" v-on="on">{{
                      (event.created_at) | moment("from", "now")
                    }}</span>
                  </template>
                  <span>{{
                    (event.created_at) | moment("DD/MM/YYYY HH:mm:ss")
                  }}</span>
                </v-tooltip>
              </div>
            </v-layout>
          </v-layout>
        </v-card-text>
      </v-card>
      <Buttons
        ref="test"
        class="message-buttons mt-2"
        v-if="event.content.buttons_type"
        :selectedInitiative="event.content"
      ></Buttons>
      <!-- :filteredButtons="filteredButtons" -->
    </v-layout>
  </v-layout>
</template>

<script>
import { mapActions } from "vuex";
import Header from "./Header.vue";
import Buttons from "./Buttons.vue";

export default {
  props: ["event", "debug"],
  components: {
    Header,
    Buttons,
    // StatusSentMessage,
  },

  methods: {
    checkDocType(file_name, types) {
      let name_splitted = file_name.split(".");
      let type = name_splitted[name_splitted.length - 1];
      return types.indexOf(type) > -1;
    },
    doneAllIconColor(was_seen) {
      if (was_seen){ return 'blue' }
      else { return '' }
    }
  },
};
</script>

<style lang="scss" scoped>
span.footer-text2 {
  font-size: 16px;
}

p {
  font-size: 16px;
  position: relative;
  margin: 0;
  top: 0px;
}
.v-card__text {
  padding: 10px !important;
}
.tes {
  max-width: 320px;
}
.row-message {
  max-height: max-content;

  .message {
    border-radius: 15px;
    border-radius: 12px 12px 0 0px;

    .box-message {
      border-radius: 12px 12px 0 12px;
      padding: -0px;
      min-width: 240px;
      max-width: 440px;
      z-index: 2;

      .dont-break-out {
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;

        /* Adds a hyphen where the word breaks, if supported (No Blink) */
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
      }

      .keep-text {
        max-width: 380px;
      }

      &.debug {
        border-radius: 12px 12px 12px 0;
      }
    }

    .date-event {
      font-size: 12px;
      margin-left: 10px;
      margin-top: 15px;
    }

    .box-status {
      min-width: 17px;
      margin-top: -5px;
    }

    &.debug {
      border-radius: 12px 12px 12px 0;
    }
  }
  .light-box-message {
    background-color: #d9fdd3 !important;
    background: #d9fdd3 !important;
  }

  .dark-box-message {
    background-color: #005c4b !important;
    background: #005c4b !important;
  }
}
</style>

<!-- <v-layout class="dont-break-out">
                        <v-flex v-if="event.referent.payload && event.referent.payload.body">
                            <v-layout v-if="event.referent.payload.body.processed" column>
                              {{event.referent.payload.body.processed}}
                            </v-layout>
                                <!- <span v-if="event.referent.content.caption">{{event.referent.content.caption}}</span> ->
                        </v-flex>

                        <v-flex grow></v-flex>

                        <v-flex class="pb-1 pl-2" shrink>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-avatar :size="21" color="grey lighten-4" v-on="on">
                                        <img v-if="event.referent.user" :src="event.referent.user.profile_photo_url" alt="avatar">
                                    </v-avatar>
                                </template>
                                <span v-if="event.referent.user">{{event.referent.user.name}}</span>
                                <span v-if="!event.referent.user">Dodo</span>
                            </v-tooltip>
                        </v-flex>
                    </v-layout>

                    <v-layout align-end justify-end>
                        <v-layout shrink>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <span class="date-event" v-on="on">{{event.timestamp + 'Z' | moment("from", "now")}}</span>
                                </template>
                                <span>{{event.timestamp + 'Z' | moment('DD/MM/YYYY HH:mm:ss')}}</span>
                            </v-tooltip>
                            <status-sent-message :message="event.referent" :key="event.referent.id"/>
                        </v-layout>
                    </v-layout> -->

        <!-- </v-layout> -->